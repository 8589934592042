<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>日志管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.userName" placeholder="请输入用户名称" clearable/>
                </FormItem>
                <FormItem>
                    <Input v-model="filter.loginIp" placeholder="请输入IP地址" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="logList">
                    <template slot-scope="{ row, index }" slot="status">
                        <span
                            v-bind:class="{temporary: (row.loginStatus)==='1'}">{{ row.loginStatus === '0' ? '登录成功' : '登录失败' }}</span>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum" @on-change="changePage"
                              @on-page-size-change="changPageSize" :page-size-opts="[15,20,50,100]" show-sizer
                              show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import {reqGetLogListPage} from "../../../api/system-api";
import permission from '../../../common/permission';

export default {
    name: 'TableView',
    data() {
        return {
            filter: {
                userName: '',
                loginIp: ''
            },
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    key: 'index',
                    width: 80
                },
                {
                    title: '用户名',
                    key: 'username',
                    width: 200
                },
                {
                    title: '登录IP',
                    key: 'loginIp'
                },
                {
                    title: '登录状态',
                    key: 'loginStatus',
                    slot: 'status',
                    width: 150
                },
                {
                    title: '登录时间',
                    key: 'formatCreateTime',
                    width: 200
                },
            ],
            permissionButton: {
                add: false,
                edit: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 20,
            logList: [],
            size: 'large',
            noDataText: '无数据',
            noFilteredDataText: '无筛选数据'
        }
    },
    methods: {
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getLoginLogList();
        },
        //每页显示的数据条数
        changPageSize(index) {
            //实时获取当前需要显示的条数
            this.pageSize = index;
            this.pageNum = 1;
            this.getLoginLogList();
        },
        doSearching() {
            this.pageNum = 1;
            this.getLoginLogList();
        },
        //获取分页列表
        getLoginLogList() {
            let that = this;
            let para = {
                pageNum: that.pageNum,
                pageSize: that.pageSize,
                userName: that.filter.userName,
                loginIp: that.filter.loginIp
            };
            reqGetLogListPage(para).then((res) => {
                that.total = res.data.data.total;
                that.logList = res.data.data.records;
                that.pages = res.data.data.pages;
            })
        },
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.getLoginLogList();
    }
}
</script>
